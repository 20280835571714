import React from "react"
import {graphql} from "gatsby"
import Layout from "../components/layout/layout"
import ArticleFull from "../components/node/article-full";

export default ({ data, location }) => {
  return (
    <Layout entity={data.drupal.nodeById.entityTranslation}>
      <ArticleFull location={location} entity={data.drupal.nodeById.entityTranslation} />
    </Layout>
  )
}

export const query = graphql`
  query($id: String!, $languageId: Drupal_LanguageId!) {
    drupal {
      nodeById(id: $id) {
        ... on Drupal_NodeArticle {
          entityTranslation(language: $languageId) {
            ...ArticleFull
          }
        }
      }
    }
  }
`;
