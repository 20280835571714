import React, {useContext} from "react";
import "./article-full.scss"
import { GatsbyImage } from "gatsby-plugin-image";
import Body from "./body";
import {Link} from "gatsby";
import {SiteContext} from "../../context/site-context";
import {Disqus} from "gatsby-plugin-disqus";
import ImageSquare from "../media/image-square";

const ArticleFull = (props) => {

  const siteContext = useContext(SiteContext);
  const i18next = siteContext.i18next;

  let dateCreated = new Date(props.entity.created * 1000);

  const monthNames = [
    i18next.t('date:January'),
    i18next.t('date:February'),
    i18next.t('date:March'),
    i18next.t('date:April'),
    i18next.t('date:May'),
    i18next.t('date:June'),
    i18next.t('date:July'),
    i18next.t('date:August'),
    i18next.t('date:September'),
    i18next.t('date:October'),
    i18next.t('date:November'),
    i18next.t('date:December'),
  ];

  let disqusConfig = {
    url: props.location.href,
    identifier: props.entity.entityId,
    title: props.entity.entityLabel,
  };

  return (
    <div className="article-full">
      <div className="article-image">
        <GatsbyImage alt={props.entity.entityLabel} image={{...props.entity.fieldImage.imageGatsbyFile.childImageSharp.gatsbyImageData}} />
        <h1>{props.entity.entityLabel}</h1>
      </div>
      <div className="article-content">
        <div className="article-meta">
          <ImageSquare
            alt='Albert Skibinski'
            filename={`albert.png`}
            sizes='(max-width: 400px) 100px, (max-width: 800px) 200px, 200px'
          />
          <div className="article-meta--info">
            <div className="date"><span className="meta-value">{i18next.t('Created on')}:</span><span className="meta-value">{`${dateCreated.getDate()} ${monthNames[dateCreated.getMonth()]} ${dateCreated.getFullYear()}`}</span></div>
            {/*{props.entity.entityOwner.name}*/}
            <div className="author"><span className="meta-value">{i18next.t('By')}:</span><span className="meta-value"><Link to={`/${i18next.language}/about`}>Albert Skibinski</Link></span></div>
            {/*{dateCreated !== dateUpdated &&*/}
            {/* <><span className="meta-value">{i18next.t('Last update')}:</span><span className="meta-value">{`${dateUpdated.getDate()} ${monthNames[dateUpdated.getMonth()]} ${dateUpdated.getFullYear()}`}</span></>*/}
            {/*}*/}
          </div>
        </div>
        <Body className="article-body">{props.entity.body.value}</Body>
        <Disqus config={disqusConfig} />
      </div>
    </div>
  )
};

export default ArticleFull;
