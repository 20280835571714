import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { useStaticQuery, graphql } from "gatsby"

export default (props) => {

  const images = useStaticQuery(graphql`
    query ImageSquareQuery {
      data: allFile {
        edges {
          node {
            relativePath
            childImageSharp {
              gatsbyImageData(
                width: 400
              )
            }
          }
        }
      }
    }
  `);

  const image = images.data.edges.find(n => {
    return n.node.relativePath.includes(props.filename);
  });

  if (!image) {
    return null;
  }

  return (
    <GatsbyImage alt={props.alt} image={{
      ...image.node.childImageSharp.gatsbyImageData
    }} />
  )
}
